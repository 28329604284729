import { FC } from 'react';
import { Box } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ContainedButton } from '@onc/composite-components';
import { Grid, Paper, Typography } from 'base-components';
import Form from 'library/CompositeComponents/form/Form';
import FormTextField from 'library/CompositeComponents/form/FormTextField';
import usePost from 'util/hooks/useDmasAPI/usePost';
import { useSnackbars } from 'util/hooks/useSnackbars';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    padding: theme.spacing(4),
  },
  header: {
    paddingBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(0),
    marginTop: theme.spacing(1.5),
  },
}));

type LoginFormMethods = {
  emailInput: string;
  passwordInput: string;
};

type AuthResponseProps = {
  dmasUserId: number;
  email: string;
  firstname: string;
  lastname: string;
};

type DetailsResponseProps = {
  DMAS_USER_EMAIL: string;
  DMAS_USER_FIRSTNAME: string;
  DMAS_USER_ID: number;
  DMAS_USER_LASTNAME: string;
  DMAS_USER_PRIVILEGE: 'RO' | 'RW';
  DMAS_USER_TOKEN: string;
};

interface LocationState {
  from: {
    pathname: string;
    search: string;
  };
}
interface Props extends RouteComponentProps<object, object, LocationState> {}

const Login: FC<Props> = ({ location, history }) => {
  const classes = useStyles(useTheme());
  const { onError, onInfo } = useSnackbars();

  const { mutate: getDetails } = usePost<
    Record<string, never>,
    DetailsResponseProps
  >(
    'UserDetailsService',
    {
      onSuccess: (payload) => {
        window.DMAS.Config.DMAS_USER_TOKEN = payload.DMAS_USER_TOKEN;
        window.DMAS.Config.DMAS_USER_ID = payload.DMAS_USER_ID;
        window.DMAS.Config.DMAS_USER_FIRSTNAME = payload.DMAS_USER_FIRSTNAME;
        window.DMAS.Config.DMAS_USER_LASTNAME = payload.DMAS_USER_LASTNAME;
        window.DMAS.Config.DMAS_USER_EMAIL = payload.DMAS_USER_EMAIL;
        window.DMAS.Config.DMAS_USER_PRIVILEGE = payload.DMAS_USER_PRIVILEGE;
        onInfo(
          `You are now logged in as ${window.DMAS.Config.DMAS_USER_FIRSTNAME} ${window.DMAS.Config.DMAS_USER_LASTNAME}`
        );
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    4
  );

  const { mutate: login } = usePost<
    { email: string; password: string },
    AuthResponseProps
  >(
    'UserAuthenticationService',
    {
      onSuccess: () => {
        getDetails({});
        if (location.state === undefined) {
          history.push('/');
        } else if (location.state.from.search === undefined) {
          history.push(location.state.from.pathname);
        } else {
          history.push(
            location.state.from.pathname + location.state.from.search
          );
        }
      },
      onError: (e) => {
        onError(e.message);
      },
    },
    1
  );

  const formMethods = useForm<LoginFormMethods>({
    mode: 'onBlur',
    defaultValues: {
      emailInput: '',
      passwordInput: '',
    },
  });

  const handleSubmit = (values) => {
    login({
      email: values.emailInput,
      password: values.passwordInput,
    });
  };

  const renderRedirectMessage = () => {
    if (location.state) {
      return (
        <Typography>
          You must login to view the page at {location.state.from.pathname}
        </Typography>
      );
    }

    return null;
  };

  return (
    <Box className={classes.container}>
      <Paper elevation={1} className={classes.paper}>
        {renderRedirectMessage()}
        <Typography variant="h4" className={classes.header}>
          Login to your account
        </Typography>
        <Form onSubmit={handleSubmit} formMethods={formMethods}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormTextField
                name="emailInput"
                translationKey="common.textfields.email"
                fullWidth
                rules={{
                  required: 'An email address is required',
                  validate: {
                    verifyEmail: (emailString) => {
                      const emailRegex = /^.+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
                      if (emailRegex.test(emailString)) return true;
                      return 'example_email@example.com';
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="passwordInput"
                translationKey="common.textfields.password"
                fullWidth
                type="password"
              />
            </Grid>
          </Grid>
          <ContainedButton
            type="submit"
            aria-label="sign in"
            translationKey="authentication.signIn"
            className={classes.button}
          />
        </Form>
      </Paper>
    </Box>
  );
};

export default withRouter(Login);
